import React from 'react'
import Layout from '../components/layout'
import SearchInput from '../components/SearchInput'
import Seo from "../components/seo"

const Israel = () => {
    return (
        <Layout>
            <Seo title="Israel" />

            <h1 className="title">Israel</h1>

            <SearchInput />

            <table>
                <tbody>
                    <tr>
                        <td>Israel Patents Online</td>
                        <td><a target="_blank" rel="noreferrer" href="https://israelpatents.justice.gov.il/">https://israelpatents.justice.gov.il/</a></td>
                    </tr>
                </tbody>
            </table>
        </Layout>
    )
}

export default Israel